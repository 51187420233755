import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import Flex from 'sholdi-primitives/atoms/Flex'
import Image from 'sholdi-primitives/atoms/Image'
import AuthLayout from '../../../components/templates/AuthLayout'

import LoginForm from 'sholdi-ui/organisms/LoginForm'
import Newsletter from './Newsletter'

function Login({ news }) {
  const router = useRouter()
  const left = useMemo(() => {
    if (news) {
      return (
        <Flex className="w-full h-full bg-primary-main justify-end">
          <Newsletter />
        </Flex>
      )
    }
    return (
      <Image
        src="/assets/images/logInImage.jpg"
        layout="fill"
        alt="login_banner"
        objectFit="cover"
      />
    )
  }, [news])

  return <AuthLayout right={left} left={<LoginForm router={router} />} reverse />
}

Login.propTypes = {
  news: PropTypes.bool,
}

export default Login
