import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const Gift = ({ className, ...props }) => (
  <SVG
    aria-label="Gift"
    viewBox="0 0 52 51"
    className={twMerge("w-12 h-12", className)}
    {...props}
  >
    <path
      d="M45.3333 25.1667V49.3333H6.66659V25.1667M25.9999 49.3333V13.0833M25.9999 13.0833H15.1249C13.5226 13.0833 11.9858 12.4468 10.8528 11.3138C9.71978 10.1807 9.08325 8.64402 9.08325 7.04167C9.08325 5.43932 9.71978 3.9026 10.8528 2.76956C11.9858 1.63653 13.5226 1 15.1249 1C23.5833 1 25.9999 13.0833 25.9999 13.0833ZM25.9999 13.0833H36.8749C38.4773 13.0833 40.014 12.4468 41.147 11.3138C42.2801 10.1807 42.9166 8.64402 42.9166 7.04167C42.9166 5.43932 42.2801 3.9026 41.147 2.76956C40.014 1.63653 38.4773 1 36.8749 1C28.4166 1 25.9999 13.0833 25.9999 13.0833ZM1.83325 13.0833H50.1666V25.1667H1.83325V13.0833Z"
      stroke="#0059AD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

Gift.propTypes = {
  className: PropTypes.string,
};

export default Gift;
