import { useState } from 'react'

import Eye from 'sholdi-icons/Eye'
import EyeSlash from 'sholdi-icons/EyeSlash'

const usePasswordType = () => {
  const [passwordType, setPasswordType] = useState('password')
  const icon = passwordType === 'text' ? EyeSlash : Eye

  const iconToggle = e => {
    e.preventDefault()
    e.stopPropagation()
    setPasswordType(passwordType === 'text' ? 'password' : 'text')
  }
  return { iconToggle, passwordType, icon }
}

export default usePasswordType
