import Head from 'next/head'

import LoginPage from '../components/pageviews/Login'

import { withServerSideProps } from 'sholdi-hocs/withServerSideProps'

function Login() {
  return (
    <>
      <Head>
        <title>Prijava | Sholdi</title>
      </Head>
      <LoginPage news />
    </>
  )
}
export const getServerSideProps = withServerSideProps()

export default Login
