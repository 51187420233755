import useTranslation from 'sholdi-hooks/useTranslation'

import Gift from 'sholdi-icons/Gift'
import Flex from 'sholdi-primitives/atoms/Flex'
import Box from 'sholdi-primitives/atoms/Box'
import Text from 'sholdi-primitives/atoms/Text'
import NewsletterSignUp from 'sholdi-ui/molecules/NewsletterSignUp'

export default function Newsletter() {
  const { t } = useTranslation()

  return (
    <Flex
      maxWidth="800px"
      className="max-w-200 px-18 lg:px-30 justify-center items-center flex-col w-full h-full"
    >
      <Box className="bg-primary-lighter p-12.5 rounded-full">
        <Gift />
      </Box>
      <Text className="mt-10 text-white font-bold text-2xl font-heading" as="h3">
        {t('freeGift')}
      </Text>
      <Text as="h4" className="text-center text-white text-base font-heading mt-4">
        {t('newsletterSignup')}
      </Text>
      <NewsletterSignUp className={'mt-10'} />
    </Flex>
  )
}
