import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { getSession, signIn } from "next-auth/react";
import { useFormik, FormikProvider } from "formik";
import dynamic from "next/dynamic";

import useTranslation from "sholdi-hooks/useTranslation";
import usePasswordType from "sholdi-hooks/usePasswordType";

import isChromeExtension from "sholdi-shared/helpers/isChromeExtension";

import Box from "sholdi-primitives/atoms/Box";
import Logo from "sholdi-primitives/atoms/LogoIcon";
import Text from "sholdi-primitives/atoms/Text";
import Link from "sholdi-primitives/atoms/Link";
import Alert from "sholdi-primitives/atoms/Alert";
import Field from "../../molecules/Field";
import FormikField from "../../molecules/Field/FormikField";

import { useGetValidationSchema, initial } from "./formValues";

const AuthActions = dynamic(() => import("../../molecules/AuthActions"));

export default function LoginForm({ router, error: errorMessage, onSubmit }) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (router?.query.error) setError(t("users.facebookEmailRequired"));
  }, [router?.query, t]);

  const formik = useFormik({
    initialValues: initial,
    validationSchema: useGetValidationSchema(t),
    onSubmit: async (values) => {
      setError(null);
      setLoading(true);
      const { email, ...rest } = values;
      if (onSubmit) {
        onSubmit(values, setLoading);
        return;
      }
      const res = await signIn("credentials", {
        email: email.trim().toLowerCase(),
        ...rest,
        admin: router?.pathname.includes("admin"),
        redirect: false,
      });
      if (res.error) {
        setError(t("users.invalidCredentials"));
        setLoading(false);
      } else {
        const data = await getSession();
        setLoading(false);
        if (data && !isChromeExtension()) {
          window.location.href = router?.query.callbackUrl ?? "/";
        }
      }
    },
  });

  const {
    setFieldValue,
    setFieldTouched,
    values: { password },
    errors,
    touched,
    handleSubmit,
  } = formik;

  const { icon: Icon, iconToggle, passwordType } = usePasswordType();

  return (
    <FormikProvider value={formik}>
      <Box
        as="form"
        onSubmit={handleSubmit}
        className="w-full lg:w-[350px] max-w-full lg:max-w-[350px] px-4 lg:px-0"
      >
        <Link href="/" className="block w-15 mb-4">
          <Logo className="h-full w-unset size-15" />
        </Link>
        <Text
          as="h1"
          className="font-semibold font-heading leading-10 mb-8 text-4xl md:text-4xl"
        >
          {t("users.logIn")}
        </Text>
        {(error || errorMessage) && (
          <Alert variant="error" className="mb-4">
            {error || errorMessage}
          </Alert>
        )}
        <FormikField
          variant="none"
          name="email"
          label={`${t("form.emailAddress")}*`}
          placeholder={t("form.emailAddress")}
          // className="mb-5"
        />
        <Field
          variant="none"
          name="password"
          label={`${t("form.password")}*`}
          placeholder={t("form.enterCurrentPassword")}
          autoComplete="current-password"
          type={passwordType}
          value={password}
          onBlur={() => setFieldTouched("password", true)}
          onChange={(e) => setFieldValue("password", e.target.value)}
          error={errors.password}
          aria-label="password"
          touched={touched.password}
          after={<Icon onClick={iconToggle} className="cursor-pointer" />}
        />
        <div className="flex w-full justify-between py-0.5">
          {/* <CheckboxField
            checked={rememberMe}
            name="rememberMe"
            onChange={e => setFieldValue('rememberMe', e.target.checked)}
            label={t('users.rememberHowLong', { num: 30 })}
            size="sm"
            ariaLabel="remember-me"
            pr={3}
            mt={5}
          /> */}

          {!isChromeExtension() && (
            <Link
              href="/forgot-password"
              className="font-medium text-primary-dark mt-5.5 text-sm md:text-sm mb-2"
            >
              {t("users.forgotPassword")}
            </Link>
          )}
        </div>
        <AuthActions label="users.logIn" loading={loading} />
      </Box>
    </FormikProvider>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  router: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.shape({
      callbackUrl: PropTypes.string,
      error: PropTypes.string,
    }),
  }),
};
